import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main>
      <h1>Page not found</h1>
      <h2><Link to='/'>Back to homepage</Link></h2>
    </main>
  </Layout>
)

export default NotFoundPage
